import { SNACKBAR_MESSAGE } from "../constants/snackbar.constants";

export const setSnackbarStatus = (status?: string) => ({
  type: SNACKBAR_MESSAGE,
  payload: status,
});

export const showSnackbarStatus = (status: string) => (dispatch: any) => {
  dispatch(setSnackbarStatus(status));
  setTimeout(() => {
    dispatch(setSnackbarStatus());
  }, 4000);
};
