import {
  OPEN_PUMP_DATA_SELECTION_MODAL,
  CLOSE_PUMP_DATA_SELECTION_MODAL,
  OPEN_GRAPH_HI2_SELECTION_MODAL,
  CLOSE_GRAPH_HI2_SELECTION_MODAL,
} from "../constants/graph.constants";

const initialState = {
  isPumpDataSelectionModalOpen: false,
  isHI2SelectionModalOpen: false
};

const graphReducer = (
  localState: Object = initialState,
  action: any
): Object => {
  switch (action.type) {
    case OPEN_PUMP_DATA_SELECTION_MODAL:
      return {
        ...localState,
        isPumpDataSelectionModalOpen: true,
      };
    case CLOSE_PUMP_DATA_SELECTION_MODAL:
      return {
        ...localState,
        isPumpDataSelectionModalOpen: false,
      };
    case OPEN_GRAPH_HI2_SELECTION_MODAL: {
      return {
        ...localState,
        isHI2SelectionModalOpen: true,
      };
    }
    case CLOSE_GRAPH_HI2_SELECTION_MODAL: {
      return {
        ...localState,
        isHI2SelectionModalOpen: false,
      };
    }
    default:
      return localState;
  }
};

export default graphReducer;
