export const CREATE_FLOWMETER_SUCCESS = "CREATE_FLOWMETER_SUCCESS";
export const CREATE_FLOWMETER_ERROR = "CREATE_FLOWMETER_ERROR";
export const CREATE_FLOWMETER_PENDING = "CREATE_FLOWMETER_PENDING";
export const CLEAR_FLOWMETER_ERROR = "CLEAR_FLOWMETER_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_FLOWMETER_MODAL = "OPEN_CREATE_FLOWMETER_MODAL";
export const CLOSE_CREATE_FLOWMETER_MODAL = "CLOSE_CREATE_FLOWMETER_MODAL";
export const SET_FLOWMETERS_PENDING = "SET_FLOWMETERS_PENDING";
export const SET_FLOWMETERS_LIST = "SET_FLOWMETERS_LIST";
export const SET_FLOWMETER_DETAILS_INITIAL_STATE = "SET_FLOWMETER_DETAILS_INITIAL_STATE";
export const SET_FLOWMETER_DETAILS = "SET_FLOWMETER_DETAILS";
export const GET_FLOWMETER_DETAILS_PENDING = "GET_FLOWMETER_DETAILS_PENDING";
export const GET_FLOWMETER_DETAILS_SUCCESS = "GET_FLOWMETER_DETAILS_SUCCESS";
export const CLOSE_EDIT_FLOWMETER_MODAL = "CLOSE_EDIT_FLOWMETER_MODAL";
export const OPEN_EDIT_FLOWMETER_MODAL = "OPEN_EDIT_FLOWMETER_MODAL";
export const UPDATE_FLOWMETER_PENDING = "UPDATE_FLOWMETER_PENDING";
export const UPDATE_FLOWMETER_SUCCESS = "UPDATE_FLOWMETER_SUCCESS";
export const UPDATE_FLOWMETER_ERROR = "UPDATE_FLOWMETER_ERROR";
export const CLEAR_UPDATE_FLOWMETER_ERROR = "CLEAR_UPDATE_FLOWMETER_ERROR";
export const SET_FLOWMETER_LIST_ASYNC = "SET_FLOWMETER_LIST_ASYNC";
