export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const SET_PRINT_INITIAL_STATE = "SET_PRINT_INITIAL_STATE";
export const OPEN_TEST_SELECTION_MODAL = "OPEN_TEST_SELECTION_MODAL";
export const OPEN_PRINT_SELECTION_MODAL = "OPEN_PRINT_SELECTION_MODAL";
export const CLOSE_TEST_SELECTION_MODAL = "CLOSE_TEST_SELECTION_MODAL";
export const CLOSE_PRINT_SELECTION_MODAL = "CLOSE_PRINT_SELECTION_MODAL";
export const SET_PUMP_DATA_LIST_PENDING = "SET_PUMP_DATA_LIST_PENDING";
export const SET_PUMP_DATA_LIST = "SET_PUMP_DATA_LIST";
export const SET_TEST_SELECTION_COMBINATION = "SET_TEST_SELECTION_COMBINATION";
export const CLEAR_TEST_SELECTION_COMBINATION =
  "CLEAR_TEST_SELECTION_COMBINATION";
export const SET_GENERAL_DATA = "SET_GENERAL_DATA";
export const SET_TEST_DATA = "SET_TEST_DATA";
export const SET_NPSH_DATA = "SET_NPSH_DATA";
export const SET_OUTPUT_TEST_DATA = "SET_OUTPUT_TEST_DATA";
export const SET_OUTPUT_NPSH_DATA = "SET_OUTPUT_NPSH_DATA";
export const GET_PUMP_DETAILS_PENDING = "GET_PUMP_DETAILS_PENDING";
export const GET_PUMP_DETAILS_SUCCESS = "GET_PUMP_DETAILS_SUCCESS";
export const SAVE_PUMP_DATA_PENDING = "SAVE_PUMP_DATA_PENDING";
export const SAVE_PUMP_DATA_ERROR = "SAVE_PUMP_DATA_ERROR";
export const SAVE_PUMP_DATA_SUCCESS = "SAVE_PUMP_DATA_SUCCESS";
export const CLEAR_SAVE_PUMP_DATA_ERROR = "CLEAR_SAVE_PUMP_DATA_ERROR";
export const OPEN_HI2_SELECTION_MODAL = "OPEN_HI2_SELECTION_MODAL";
export const CLOSE_HI2_SELECTION_MODAL = "CLOSE_HI2_SELECTION_MODAL";
export const SET_PUMP_FAMILY_LIST = "SET_PUMP_FAMILY_LIST";
export const SET_PUMP_SUBTYPE_LIST = "SET_PUMP_SUBTYPE_LIST";
export const SET_PUMP_SUBTYPE_DATA = "SET_PUMP_SUBTYPE_DATA";
export const GET_PUMP_FAMILY_LIST_PENDING = "GET_PUMP_FAMILY_LIST_PENDING";
export const GET_PUMP_FAMILY_LIST_SUCCESS = "GET_PUMP_FAMILY_LIST_SUCCESS";
export const GET_PUMP_FAMILY_LIST_ERROR = "GET_PUMP_FAMILY_LIST_ERROR";
export const GET_PUMP_SUBTYPE_PENDING = "GET_PUMP_SUBTYPE_PENDING";
export const GET_PUMP_SUBTYPE_SUCCESS = "GET_PUMP_SUBTYPE_SUCCESS";
export const GET_PUMP_SUBTYPE_ERROR = "GET_PUMP_SUBTYPE_ERROR";
export const GET_PUMP_SUBTYPE_DATA_PENDING = "GET_PUMP_SUBTYPE_DATA_PENDING";
export const GET_PUMP_SUBTYPE_DATA_SUCCESS = "GET_PUMP_SUBTYPE_DATA_SUCCESS";
export const GET_PUMP_SUBTYPE_DATA_ERROR = "GET_PUMP_SUBTYPE_DATA_ERROR";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const GET_GRAPH_DATA_PENDING = "GET_GRAPH_DATA_PENDING";
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";
export const SET_FILTERED_PUMP_DATA_IDS = "SET_FILTERED_PUMP_DATA_IDS";
export const GET_TEST_DATA_PENDING = "GET_TEST_DATA_PENDING";
export const GET_TEST_DATA_SUCCESS = "GET_TEST_DATA_SUCCESS";
export const GET_NPSH_DATA_PENDING = "GET_NPSH_DATA_PENDING";
export const GET_NPSH_DATA_SUCCESS = "GET_NPSH_DATA_SUCCESS";
export const SET_ACTIVE_PUMP_FAMILY = "SET_ACTIVE_PUMP_FAMILY";
export const SET_ACTIVE_PUMP_SUBTYPE = "SET_ACTIVE_PUMP_SUBTYPE";
export const SET_ACTIVE_PUMP_SUBTYPE_DATA = "SET_ACTIVE_PUMP_SUBTYPE_DATA";
export const CLEAR_ACTIVE_PUMP_HI2_SELECTION =
  "CLEAR_ACTIVE_PUMP_HI2_SELECTION";
export const SET_PUMP_DATA_LIST_FILTER = "SET_PUMP_DATA_LIST_FILTER";
export const CLEAR_PUMP_DATA_LIST_FILTER = "CLEAR_PUMP_DATA_LIST_FILTER";
export const SET_SELECTED_PUMP_DATA_DETAILS = "SET_SELECTED_PUMP_DATA_DETAILS";
export const CLEAR_SELECTED_PUMP_DATA_DETAILS =
  "CLEAR_SELECTED_PUMP_DATA_DETAILS";
export const SET_EXPORT_INITIAL_STATE = "SET_EXPORT_INITIAL_STATE";
export const OPEN_EXPORT_SELECTION_MODAL = "OPEN_EXPORT_SELECTION_MODAL";
export const CLOSE_EXPORT_SELECTION_MODAL = "CLOSE_EXPORT_SELECTION_MODAL";
export const SET_PUMP_DATA_LIST_PAGER = "SET_PUMP_DATA_LIST_PAGER";
export const CLEAR_PUMP_DETAILS = "CLEAR_PUMP_DETAILS";
export const ADD_FILTERED_PUMP_DATA_IDS = "ADD_FILTERED_PUMP_DATA_IDS";
export const RESET_CAN_OPEN_PUMP_DETAILS_FLAG = "RESET_CAN_OPEN_PUMP_DETAILS_FLAG";
export const SET_PUMP_DATA_COPIED_FLAG = "SET_PUMP_DATA_COPIED_FLAG";
export const RESET_PUMP_DATA_COPIED_FLAG = "RESET_PUMP_DATA_COPIED_FLAG";

export const TEST_CONDITIONS_LIST = [
  {
    label: "Bypass",
    value: "BP",
  },
  {
    label: "Torque meter fixed speed",
    value: "TMFS",
  },
  {
    label: "Torque meter variable speed",
    value: "TMVS",
  },
];

export const APPROVED_LIST = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "True",
    value: true,
  },
  {
    label: "False",
    value: false,
  },
];

export const BASIC_CURVE_LIST = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "True",
    value: true,
  },
  {
    label: "False",
    value: false,
  },
];

export const PUMP_DATA_SORTING_OPTIONS = [
  {
    label: "Test No: Low to High",
    value: "testNo#asc",
  },
  {
    label: "Test No: High to Low",
    value: "testNo#desc",
  },
  {
    label: "Date: Low to High",
    value: "testDate#asc",
  },
  {
    label: "Date: High to Low",
    value: "testDate#desc",
  },
  {
    label: "Pump No: Low to High",
    value: "pumpNo#asc",
  },
  {
    label: "Pump No: High to Low",
    value: "pumpNo#desc",
  },
  {
    label: "Pump: Low to High",
    value: "pumpFamily#asc",
  },
  {
    label: "Pump: High to Low",
    value: "pumpFamily#desc",
  },
  {
    label: "Stages: Low to High",
    value: "pumpMultSt#asc",
  },
  {
    label: "Stages: High to Low",
    value: "pumpMultSt#desc",
  },
  {
    label: "Order No: Low to High",
    value: "orderNo#asc",
  },
  {
    label: "Order No: High to Low",
    value: "orderNo#desc",
  },
  {
    label: "Customer: Low to High",
    value: "customerName#asc",
  },
  {
    label: "Customer: High to Low",
    value: "customerName#desc",
  },
  {
    label: "Customer Pump No: Low to High",
    value: "customerPumpNo#asc",
  },
  {
    label: "Customer Pump No: High to Low",
    value: "customerPumpNo#desc",
  },
];

export const PUMP_DATA_SEARCH_OPTIONS = [
  {
    label: "Test No",
    value: "testNo",
  },
  {
    label: "Pump No",
    value: "pumpNo",
  },
  {
    label: "Pump Family",
    value: "pumpFamily",
  },
  {
    label: "Sub Type",
    value: "subType",
  },
  {
    label: "Pump Type",
    value: "pumpType",
  },
  {
    label: "STAGES",
    value: "pumpMultSt",
  },
  {
    label: "Order No",
    value: "orderNo",
  },
  {
    label: "Customer",
    value: "customerName",
  },
  {
    label: "Cust. Desc",
    value: "customerDescription",
  },
  {
    label: "Mat Code",
    value: "tcode",
  },
  {
    label: "Type",
    value: "type",
  },
  {
    label: "Speed",
    value: "speed",
  },
  {
    label: "Impeller",
    value: "twaaier",
  },
  {
    label: "Impeller Type",
    value: "imperllerType",
  },

  {
    label: "Item No",
    value: "customerItemNo",
  },
];

export const DEFAULT_SEARCH_DATA = {
  testNo: {
    data: "",
    type: "input",
  },
  pumpNo: {
    data: "",
    type: "input",
  },
  pumpFamily: {
    data: "",
    type: "input",
  },
  pumpCombit: {
    data: "",
    type: "input",
  },
  pumpType: {
    data: "",
    type: "input",
  },
  pumpMultSt: {
    data: "",
    type: "input",
  },
  orderNo: {
    data: "",
    type: "input",
  },
  customerName: {
    data: "",
    type: "input",
  },
  customerDescription: {
    data: "",
    type: "input",
  },
  tcode: {
    data: "",
    type: "input",
  },
  speed: {
    data: "",
    type: "range",
  },
  twaaier: {
    data: "",
    type: "range",
  },
  pumpWaaier: {
    data: "",
    type: "input",
  },
  meetType: {
    data: "",
    type: "input",
  },
  customerItemNo: {
    data: "",
    type: "input",
  },
};

export const SPEED_OPTIONS = [
  { label: "950", value: "950" },
  { label: "970", value: "970" },
  { label: "1150", value: "1150" },
  { label: "1170", value: "1170" },
  { label: "1450", value: "1450" },
  { label: "1480", value: "1480" },
  { label: "1750", value: "1750" },
  { label: "1780", value: "1780" },
  { label: "2900", value: "2900" },
  { label: "2960", value: "2960" },
  { label: "3500", value: "3500" },
  { label: "3550", value: "3550" },
];

export const TYPE_FILTER_OPTIONS = [
  {
    label: "All",
    value: undefined,
  },
  { label: "QB", value: "QB" },
  { label: "NB", value: "NB" },
  { label: "QK", value: "QK" },
  { label: "NK", value: "NK" },
  { label: "QV", value: "QV" },
  { label: "NV", value: "NV" },
];

export const GRAPH_TOLERANCE_OPTIONS = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "DIN 2548 - duty points",
    value: "DIN2548_dutypoints",
  },
  { label: "ISO 2548 - curve HI", value: "ISO2548_curveHI" },
  { label: "ISO 3555", value: "ISO3555" },
  { label: "API 610 : <- 10th ed.", value: "API61010thed" },
  { label: "API 610 : 11th ed. ->", value: "API61011thed" },
  { label: "DIN 1944 - class III", value: "DIN1944_classIII" },
  { label: "DIN 1944 - class II", value: "DIN1944_classII" },
  { label: "DIN 1944 class I", value: "DIN1944classI" },
  { label: "EN-ISO 9906 Grade 1 Point", value: "DEN_ISO9906Grade1Point" },
  { label: "EN-ISO 9906 Grade 2 Curve", value: "EN_ISO9906Grade2Curve" },
  { label: "EN-ISO 9906 Grade 2 Point", value: "DEN_ISO9906Grade2Point" },
  { label: "ISO 9906 grade 1E", value: "ISO9906grade1E" },
  { label: "ISO 9906 grade 1B", value: "ISO9906grade1B" },
  { label: "ISO 9906 grade 2B", value: "ISO9906grade2B" },
  { label: "ISO 9906 grade 1U", value: "ISO9906grade1U" },
  { label: "ISO 9906 grade 2U", value: "ISO9906grade2U" },
  { label: "ISO 9906 grade 3B", value: "ISO9906grade3B" },
];

export const OUTPUT_TEST_HEADING_B = [
  "No.",
  "Q [m3/h]",
  "V [m]",
  "H [m]",
  "eff M [%]",
  "Pw [kW]",
  "QI [m3/h]",
  "HI [m]",
  "Pw [kW]",
  "PI [kW]",
  "eff P[%]",
];

export const OUTPUT_TEST_HEADING_KV = [
  "No.",
  "Q [m3/h]",
  "V [m]",
  "H [m]",
  " ",
  "Pw [kW]",
  "QI [m3/h]",
  "HI [m]",
  "Pw [kW]",
  "PI [kW]",
  "eff P[%]",
];

export const OUTPUT_NPSH_HEADING = [
  "No.",
  "Q [m3/h]",
  "P1 [m]",
  "V1^2/2g [m]",
  "NPSH [m]",
  " ",
  "Q [m3/h]",
  "NPSH [m]",
];

export const DECIMAL_VALUES = {
  tWaterTemp: 1,
};

export const validNumber = (value) => {
  if (Number.isFinite(value)) return value;
  else return;
};

export const INLET_OPTIONS = [
  "50",
  "65",
  "80",
  "100",
  "150",
  "200",
  "250",
  "300",
  "350",
  "1000000",
];

export const OUTLET_OPTIONS = [
  "50",
  "65",
  "80",
  "100",
  "150",
  "200",
  "250",
  "300",
  "350",
];
