import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import configureStore from "../store/store";
import { logoutUser } from "../store/actions/actions";

const superagent = superagentPromise(_superagent, Promise);

const responseBody = (res) => res.body;
const apiUrl = window.__ENV && window.__ENV.apiUrl;

const handleErrors = (err) => {
  if (err && err.status === 401) {
    const store = configureStore();
    store.dispatch(logoutUser());
  }
};

const requests = {
  post: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .then(responseBody),
  postData: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .on("error", handleErrors)
      .then(responseBody),
  getWithToken: (url, token) =>
    superagent
      .get(url)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  postWithToken: (url, body, token) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  deleteWithToken: (url, token) =>
    superagent
      .del(url, token)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  putWithToken: (url, body, token) =>
    superagent
      .put(url, body, token)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  uploadWithToken: (url, data, token) =>
    superagent
      .post(url, data)
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
};

const Auth = {
  login: (email: string, password: string) => {
    const formData =
      "username=" +
      encodeURIComponent(email) +
      "&password=" +
      encodeURIComponent(password) +
      "&grant_type=password";
    return requests.postData(`${apiUrl}/token`, formData);
  },
  getCurrentUser: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/api/user/profile`, access_token);
  },
  enableAccount: (data: any, code) => {
    const { password, confirmPassword } = data;
    return requests.postData(`${apiUrl}/api/user/enableuser`, {
      password,
      confirmPassword,
      code,
    });
  },
  forgotPassword: (email: string) => {
    return requests.postData(`${apiUrl}/api/user/forgotpassword`, {
      email,
    });
  },
  resetPassword: (data: any, code) => {
    const { password, confirmPassword, email } = data;
    return requests.postData(`${apiUrl}/api/user/resetpassword`, {
      password,
      confirmPassword,
      code,
      email,
    });
  },
};

const User = {
  createUser: (access_token: string, user: Object) => {
    const {
      firstName,
      lastName,
      displayName,
      email,
      mobileNumber,
      new_user_role,
      isNotify,
    } = user;
    return requests.postWithToken(
      `${apiUrl}/api/user/create`,
      {
        firstName,
        lastName,
        displayName,
        email,
        mobileNumber,
        notifyUser: isNotify,
        roleId: [new_user_role && new_user_role.value],
        //new_user_role.map(function (item) {
        //  return item["value"];
        //}),
      },
      access_token
    );
  },
  getUserDetails: (access_token: string, userId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/user/details?userId=${userId}`,
      access_token
    );
  },
  sendResetPasswordEmail: (email: string) => {
    return requests.post(`${apiUrl}/api/user/forgotpassword`, { email });
  },
  changePassword: (access_token: string, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/user/changepassword`,
      data,
      access_token
    );
  },
  updateUser: (access_token: string, data: any) => {
    data = {
      ...data,
      roleId: [data.selected_user_role && data.selected_user_role.value],
       //data.selected_user_role.map(function (item) {
        //return item["value"];
      //}),
    };
    return requests.postWithToken(
      `${apiUrl}/api/user/update`,
      data,
      access_token
    );
  },
  getUsers: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/userlist?$count=true&$orderby=displayName asc`,
      access_token
    );
  },
  getAssignableRoles: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/api/user/roles`, access_token);
  },
};

const PumpData = {
  getPumpDataList: (
    access_token: string,
    currentPage: Number,
    pageSize: Number,
    url: string
  ) => {
    console.log(url);
    return requests.getWithToken(
      `${apiUrl}/odata/PumpList?$count=true${url}&$skip=${
        (currentPage - 1) * pageSize
      }&$top=${pageSize}`,
      access_token
    );
  },
  getGeneralData: (access_token: String, testNo: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/pump?id=${testNo}`,
      access_token
    );
  },
  savePumpData: (access_token: String, data: any, unlock: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/pump/save?unlock=${unlock}`,
      data,
      access_token
    );
  },
  getTestData: (access_token: String, data: any) => {
    return requests.postWithToken(`${apiUrl}/api/TestData`, data, access_token);
  },
  getNPSHData: (access_token: String, data: any) => {
    return requests.postWithToken(`${apiUrl}/api/NPSH`, data, access_token);
  },
  deletePumpData: (access_token: String, id: String) => {
    return requests.postWithToken(
      `${apiUrl}/api/pump/delete?id=${id}`,
      null,
      access_token
    );
  },
  getPumpFamilies: (access_token: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/hi2/PumpFamilies`,
      access_token
    );
  },
  getPumpSubTypes: (access_token: String, pumpFamily: String) => {
    return requests.getWithToken(
      `${apiUrl}/api/hi2/PumpSubTypes?family=${pumpFamily}`,
      access_token
    );
  },
  getPumpSubTypeData: (
    access_token: String,
    pumpFamily: String,
    pumpSubType: String
  ) => {
    return requests.getWithToken(
      `${apiUrl}/api/hi2/PumpSubTypeData?family=${pumpFamily}&subType=${pumpSubType}`,
      access_token
    );
  },
  getGraphData: (access_token: String, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/Graph/GraphData`,
      data,
      access_token
    );
  },
  saveTempPumpData: (access_token: String, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/pump/tempSave`,
      data,
      access_token
    );
  },
};

const FlowMeter = {
  getFlowMeters: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/FlowMeterList?$count=true&$orderby=flowMeterNo`,
      access_token
    );
  },
  createFlowMeter: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/flowmeter/update`,
      {
        flowMeterMake: data.make,
        inLetDia: data.inlet,
        outLetDia: data.outlet,
        correction: data.correction,
      },
      access_token
    );
  },
  getFlowMeterDetails: (access_token: string, id: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/flowmeter/details?id=${id}`,
      access_token
    );
  },
  updateFlowMeter: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/flowmeter/update`,
      data,
      access_token
    );
  },
  removeFlowMeter: (
    access_token: String,
    id: string
  ) => {
    return requests.postWithToken(
      `${apiUrl}/api/flowmeter/delete?id=${id}`,
      null,
      access_token
    );
  },
};

const Import = {
  importData: (access_token: String, data: any) => {
    var dataToSend = new FormData();

    if (data && data.files.length > 0) {
      for (var i in data.files) {
        dataToSend.append(`file${i}`, data.files[i]);
      }
    }

    return requests.uploadWithToken(
      `${apiUrl}/api/import/dbfile`,
      dataToSend,
      access_token
    );
  },
};

const Export = {
  exportData: (access_token: String, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/csv/export`,
      data,
      access_token
    );
  },
};

const UploadCSV = {
  uploadCSV: (access_token: String, data: any) => {
    var dataToSend = new FormData();

    if (data && data.files.length > 0) {
      for (var i in data.files) {
        dataToSend.append(`file${i}`, data.files[i]);
      }
    }

    return requests.uploadWithToken(
      `${apiUrl}/api/csv/upload`,
      dataToSend,
      access_token
    );
  },
};

export default {
  Auth,
  User,
  PumpData,
  FlowMeter,
  Import,
  Export,
  UploadCSV,
};
