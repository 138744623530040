import {
  CLEAR_ENABLE_USER_ERROR,
  CLEAR_LOGIN_ERROR,
  ENABLE_USER_ERROR,
  ENABLE_USER_PENDING,
  ENABLE_USER_PENDING_ERROR,
  ENABLE_USER_PENDING_SUCCESS,
  LOGIN_ERROR,
  LOGIN_PENDING,
  LOGIN_PENDING_ERROR,
  LOGIN_PENDING_SUCCESS,
  LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../constants/login.constants";

export const loginUserPending = (): Action => ({
  type: LOGIN_PENDING,
});

export const loginUserPendingError = (): Action => ({
  type: LOGIN_PENDING_ERROR,
});

export const loginUserPendingSuccess = (): Action => ({
  type: LOGIN_PENDING_SUCCESS,
});

export const loginUserSuccess = (
  email: string,
  token: string,
  expires: number
): Action => ({
  type: LOGIN_SUCCESS,
  payload: { email, token, expires },
});

export const logoutUser = () => (dispatch: Function) => {
  dispatch({ type: USER_LOGOUT });
};

export const loginUserError = (errorMessage: string): Action => ({
  type: LOGIN_ERROR,
  payload: { errorMessage },
});

export const clearLoginUserError = (): Action => ({
  type: CLEAR_LOGIN_ERROR,
});

export const enableUserError = (errorMessage: string): Action => ({
  type: ENABLE_USER_ERROR,
  payload: { errorMessage },
});

export const enableUserPending = (): Action => ({
  type: ENABLE_USER_PENDING,
});

export const enableUserPendingSuccess = (): Action => ({
  type: ENABLE_USER_PENDING_SUCCESS,
});

export const clearEnableUserError = (): Action => ({
  type: CLEAR_ENABLE_USER_ERROR,
});

export const enableUserPendingError = (): Action => ({
  type: ENABLE_USER_PENDING_ERROR,
});
